import { useState } from "react";
import { useHistory, withRouter, Link } from "react-router-dom";
import Navbar from "./navbar.js";
import Navbar2 from "./navbar2.js";
import Bottomnav from "./bottomnav.js";
import Footer from "./footer.js";

function Return() {
  return (
    <>
      <Navbar />
      <Navbar2 />
      <Bottomnav />

      <div class="container-fluid ">
        <div class="row px-0 justify-content-center mx-0">
          <div class="col-lg-10 col-12 my-3">
            <div class="card pt-2 px-4 mb-lg-0 mb-5 rounded-0">
              <div class="card-body">
                <h2>রিটার্ন পলিসি</h2>
                <li>
                  ডেলিভারির সময় আপনার পণ্য ক্ষতিগ্রস্ত, ত্রুটিপূর্ণ, ভুল বা
                  অসম্পূর্ণ থাকলে, অনুগ্রহ করে Accessories-bazar অ্যাপ বা ওয়েবসাইট
                  থেকে রিটার্ন রিকোয়েস্ট করুন। Accessories-bazar এর পণ্যগুলির জন্য
                  ডেলিভারির তারিখ থেকে ৭ দিনের মধ্যে, অথবা নন- Accessories-bazar
                  পণ্যগুলির জন্য ৩ দিনের মধ্যে রিটার্ন রিকোয়েস্ট করতে হবে।
                </li>
                <li>
                  ব্যবহারের পরে বা রিটার্ন পলিসি সময়ের পরে ইলেকট্রনিক যন্ত্রপাতি
                  এবং মোবাইল ফোন সম্পর্কিত সমস্যাগুলির জন্য, অনুগ্রহ করে চেক
                  করুন যে পণ্যটি ব্র্যান্ড ওয়ারেন্টির আওতায় আছে কিনা।
                  ওয়ারেন্টি ক্লেইম করা সম্পর্কে তথ্যের জন্য, অনুগ্রহ করে
                  ব্র্যান্ড ওয়ারেন্টি পলিসি দেখুন।
                </li>
                <li>
                  সিলেক্টেড কিছু ক্যাটাগরি'র জন্য, আমরা মতের পরিবর্তন গ্রহণ করি।
                  আরও তথ্যের জন্য অনুগ্রহ করে ক্যাটাগরি অনুযায়ী রিটার্ন পলিসির
                  নীচের বিভাগটি দেখুন।
                </li>
                <p class="fw-bold mt-3">পণ্য রিটার্ন করার বৈধ কারণ সমূহ</p>
                <li>
                  ডেলিভারি করা পণ্য ক্ষতিগ্রস্থ (যেমন, পণ্যটি নষ্ট বা ভাঙা ) /
                  ত্রুটিপূর্ণ (যেমন, স্যুইচ অন হয় না)
                </li>
                <li>
                  ডেলিভারি করা পণ্যটি অসম্পূর্ণ (যেমন কোনো পণ্য নেই অথবা আংশিক
                  অনুপস্থিত )
                </li>
                <li>
                  ডেলিভারি করা পণ্যটি ভুল (যেমন ভুল পণ্য/সাইজ/রঙ বা মেয়াদ শেষ)
                </li>
                <li>
                  ডেলিভারি করা পণ্যটি পণ্যের বিবরণ বা ছবির সাথে মিল নেই (অর্থাৎ
                  পণ্যটি বিজ্ঞাপনের মতো নয়)
                </li>
                <p class="fw-bold mt-3">ক্যাটাগরি অনুযায়ী রিটার্ন পলিসি</p>
                <p>
                  অনুগ্রহপূর্বক জানিয়ে রাখছি কিছু কিছু পণ্যের ক্ষেত্রে পন্যের
                  পেজে নন - রিটার্নেবল লেখা থাকলে তা রিটার্নের উপযোগী হবে না।
                  আরো তথ্যের জন্য নিম্নে পণ্যের সম্পূর্ণ তালিকা দেখুন।
                </p>
                <p class="fw-bold mt-3">ফোন এবং ট্যাবলেট</p>
                <p>
                  <b>
                    ফোন, ট্যাবলেট, ব্যাটারি ও চার্জার, ইয়ারফোন ও হেডসেট, মোবাইল
                    ও ট্যাবলেট এক্সেসোরিজ
                  </b>{" "}
                  যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রুটিপূর্ণ, ভুল, অথবা
                  অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                  ইস্যু করা হবে। নোট: ব্যবহারকৃত পণ্য রিটার্ন নেয়া হয় না। মোবাইল
                  ফোনের ক্ষেত্রে, পণ্যটি ডেলিভারি পাবার পর ডিভাইসটি ডেড হলে আপনি
                  সরাসরি Accessories-bazar এ রিটার্ন রিকোয়েস্ট করতে পারেন (যেমন:
                  সম্পূর্ণভাবে সুইচ অন হচ্ছে না)। মোবাইল ফোনটি অ্যাক্টিভেটেড*
                  করা হলে, ম্যানুফ্যাকচারারের ওয়ারেন্টির সম্পর্কে আরও তথ্যের
                  জন্য অনুগ্রহ করে ব্র্যান্ড ওয়ারেন্টি প্রদানকারীর সাথে সরাসরি
                  যোগাযোগ করুন। *একবার সিম কার্ড প্রবেশ করানো হলে অথবা
                  ওয়াইফাইয়ের মাধ্যমে ইন্টারনেটে সংযোগ করা হলে মোবাইল ফোনটিকে
                  অ্যাক্টিভেটেড হিসেবে বিবেচনা করা হবে।
                </p>
                <p class="fw-bold mt-3">ফ্যাশন</p>
                <p>
                  <b>কাপড়, পোশাক, সানগ্লাস, জুতা এবং আনুষাঙ্গিক </b>
                  (দেশের বাইরে থেকে যে সকল নন- Accessories-bazar পণ্য শিপ হয়ে থাকে)
                  যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রুটিপূর্ণ, ভুল, অথবা
                  অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                  ইস্যু করা হবে। পণ্য অবশ্যই পরিধান করা যাবে না, ধোয়া যাবে না
                  এবং পরিবর্তিত করা যাবে না সম্পূর্ণ ট্যাগ সহ থাকতে হবে। পণ্য
                  যদি ব্যবহৃত হয়েছে এমন পাওয়া যায় তাহলে রিটার্ন রিজেক্ট করা হবে
                  এবং কাস্টমারকে পুনরায় পণ্যটি ফেরৎ পাঠানো হবে।
                  <b>
                    ব্যাগ, চশমা, জুয়েলারি, ঘড়ি, মেয়েদের ইন্টিমেট পোশাক এবং
                    ছেলেদের ভিতরের পোশাক
                  </b>
                  যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রুটিপূর্ণ, ভুল, অথবা
                  অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                  ইস্যু করা হবে। পণ্য অবশ্যই পরিধান করা যাবে না, ধোয়া যাবে না
                  এবং পরিবর্তিত করা যাবে না সম্পূর্ণ ট্যাগ সহ থাকতে হবে। পণ্য
                  যদি ব্যবহৃত হয়েছে এমন পাওয়া যায় তাহলে রিটার্ন রিজেক্ট করা হবে
                  এবং কাস্টমারকে পুনরায় পণ্যটি ফেরৎ পাঠানো হবে । যেসকল পণ্য
                  নন-রিটার্নেবল: সকল কাস্টম করা পণ্য, জুয়েলারি (সোনা, হীরা, দামি
                  পাথর ইত্যাদি)
                </p>
                <p class="fw-bold mt-3">বিউটি এবং হেলথ</p>
                <p>
                  <b>
                    মেকআপ, সুগন্ধি, ময়েশ্চারাইজার, ক্রিম, স্ক্রাবস, তেল, বাথ এবং
                    বডি অ্যাক্সেসোরিজ, এবং শরীরের আনুষাঙ্গিক, ব্যক্তিগত যত্ন এবং
                    স্বাস্থ্য, পরিপূরক খাদ্য
                  </b>
                  যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রুটিপূর্ণ, ভুল, অথবা
                  অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                  ইস্যু করা হবে। নোট: রিটার্ন পলিসির সময়কাল শেষ হবার পর যে কোনো
                  ইস্যুর জন্য, অনুগ্রহ করে চেক করুন পণ্যটির ব্র্যান্ড ওয়ারেন্টি
                  উপলব্ধ আছে কিনা। বিভিন্ন ওয়ারেন্টির ধরণ এবং
                  ম্যানুফ্যাকচারারের সাথে যোগাযোগ করার উপায় সম্পর্কিত তথ্যের
                  জন্য আমাদের ওয়ারেন্টি পলিসি পড়ুন। যেসকল পণ্য নন-রিটার্নেবল:
                  সকল ধরণের বিউটি সার্ভিস।
                </p>
                <p class="fw-bold mt-3">যন্ত্রপাতি</p>
                <p>
                  যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রুটিপূর্ণ, ভুল, অথবা
                  অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                  ইস্যু করা হবে। নোট: রিটার্ন পলিসির সময়কাল শেষ হবার পর যে কোনো
                  ডিভাইস সম্পর্কিত ইস্যুর জন্য, অনুগ্রহ করে চেক করুন পণ্যটির
                  সেলার অথবা ব্র্যান্ড ওয়ারেন্টি উপলব্ধ আছে কিনা। বিভিন্ন
                  ওয়ারেন্টির ধরণ এবং বিক্রেতা/ ম্যানুফ্যাকচারারের সাথে যোগাযোগ
                  করার উপায় সম্পর্কিত তথ্যের জন্য আমাদের ওয়ারেন্টি পলিসি
                  পড়ুন।
                </p>
                <p class="fw-bold mt-3">কম্পিউটিং এবং গেমিং</p>
                <p>
                  <b>
                    ল্যাপটপ, সার্টিফাইড রিফাব্রিসড ল্যাপটপ, কম্পিউটার, প্রসেসর,
                    প্রজেক্টর, স্টোরেজ, প্রিন্টার, হেডফোন, স্পিকার, কনসোল এবং
                    পিসি/ ভিডিও গেমস, গেমিং কনসোল এবং অ্যাক্সেসোরিজ, সফটওয়্যার
                    সিডি
                  </b>
                  যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রুটিপূর্ণ, ভুল, অথবা
                  অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                  ইস্যু করা হবে। নোট: আমরা কোনো ব্যবহৃত পণ্য রিটার্ন নিচ্ছি না।
                  ব্র্যান্ড ওয়ারেন্টি সহ ল্যাপটপের জন্য, Accessories-bazar শুধুমাত্র
                  ডিভাইসটি ডেলিভারি হবার সাথে সাথে অকেজো থাকলে (যেমন:
                  সম্পূর্ণভাবে সুইচ অন না হলে)। একবার ব্র্যান্ড সীল খুলে ফেললে
                  অথবা সরিয়ে ফেললে, ম্যানুফ্যাকচারার ওয়ারেন্টি সম্পর্কে তথ্য
                  জানতে অনুগ্রহ করে ব্র্যান্ড ওয়ারেন্টি প্রদানকারীর সাথে সরাসরি
                  যোগাযোগ করুন। যেসকল পণ্য নন-রিটার্নেবল: সকল সফটওয়্যার
                  প্রোডাক্ট যেগুলোর পণ্যের পেজে নন-রিটার্নেবল বলে লেবেল করা আছে।
                  নোট: যে কোনো সফটওয়্যার-সম্পর্কিত টেকনিক্যাল ইস্যুর জন্য,
                  ম্যানুফ্যাকচারার ওয়ারেন্টি সম্পর্কে তথ্য জানতে অনুগ্রহ করে
                  সেলার ব্র্যান্ড ওয়ারেন্টি প্রদানকারীর সাথে সরাসরি যোগাযোগ
                  করুন।
                </p>
                <p class="fw-bold mt-3">টিভি, অডিও এবং ক্যামেরা</p>
                <p>
                  <b>
                    টেলিভিশন, হেডফোন, স্পিকার, ক্যামেরা, ড্রোন, লেন্স, ফ্লাশেস,
                    ফিল্টার এবং মাইক্রোফোন, ভিডিও গ্লাসেস, রিমোট কন্ট্রোলার,
                    মিউজিক্যাল ইন্সট্রুমেন্টস, অডিও এবং বিনোদনের জিনিসপত্র,
                    পোর্টেবল প্লেয়ার, অন্যান্য অ্যাক্সেসোরিজ
                  </b>
                  যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রূটিপূর্ণ, ভুল, অথবা
                  অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                  ইস্যু করা হবে। নোট: রিটার্ন পলিসির সময়কাল শেষ হবার পর যে কোনো
                  ডিভাইস সম্পর্কিত ইস্যুর জন্য, অনুগ্রহ করে চেক করুন পণ্যটির
                  ব্র্যান্ড ওয়ারেন্টি উপলব্ধ আছে কিনা। বিভিন্ন ওয়ারেন্টির ধরণ
                  এবং ম্যানুফ্যাকচারারের সাথে যোগাযোগ করার উপায় সম্পর্কিত
                  তথ্যের জন্য আমাদের ওয়ারেন্টি পলিসি পড়ুন।
                </p>
                <p class="fw-bold mt-3">হোম এবং লিভিং</p>
                <p>
                  <b>
                    বেডিং এবং বাথ, ফার্নিচার এবং লাইটিং, রান্নাঘর এবং ডাইনিং,
                    হোম ডেকোর, হোম ইমপ্রুভমেন্ট, হাউসহোল্ড এবং হোম স্টোরেজ
                    সাপ্লাই, লন এবং বাগান, অন্যান্য আনুষাঙ্গিক
                  </b>
                  যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ,ত্রূটিপূর্ণ, ভুল, অথবা
                  অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                  ইস্যু করা হবে। নোট: রিটার্ন পলিসির সময়কাল শেষ হবার পর যে কোনো
                  ডিভাইস সম্পর্কিত ইস্যুর জন্য, অনুগ্রহ করে চেক করুন পণ্যটির
                  ব্র্যান্ড ওয়ারেন্টি উপলব্ধ আছে কিনা। বিভিন্ন ওয়ারেন্টির ধরণ
                  এবং ম্যানুফ্যাকচারারের সাথে যোগাযোগ করার উপায় সম্পর্কিত
                  তথ্যের জন্য আমাদের ওয়ারেন্টি পলিসি পড়ুন। যেসকল পণ্য
                  নন-রিটার্নেবল: যেকোনো কাস্টম করা পণ্য
                </p>
                <p class="fw-bold mt-3">স্পোর্টস এবং ট্রাভেল</p>
                <p>
                  <b>কাপড়, পোশাক, জুতা এবং সানগ্লাস</b>
                  (দেশের বাইরে থেকে যে সকল নন- Accessories-bazar পণ্য শিপ হয়ে থাকে)
                  যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রূটিপূর্ণ, ভুল, অথবা
                  অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                  ইস্যু করা হবে।
                  <b>
                    ব্যাগ এবং লাগেজ, স্পোর্ট ওয়াচ, টিম এবং র‍্যাকেট স্পোর্টস,
                    ডান্স এবং জিমন্যাস্টিক, এক্সারসাইজ এবং ফিটনেস, স্পোর্টস
                    নিউট্রিশন এবং সাপ্লিমেন্টস, আউটডোরের জিনিসপত্র, ফিটনেস এবং
                    অন্যান্য স্পোর্টসের জিনিসপত্র
                  </b>
                  যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রূটিপূর্ণ, ভুল, অথবা
                  অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                  ইস্যু করা হবে। গ্লোবাল পণ্যের জন্য, রিটার্নের গ্রহণযোগ্য
                  কারণগুলো জানতে অনুগ্রহ করে পণ্যের পেজটি চেক করুন। নোট: রিটার্ন
                  পলিসির সময়কাল শেষ হবার পর যে কোনো ডিভাইস সম্পর্কিত ইস্যুর
                  জন্য, অনুগ্রহ করে চেক করুন পণ্যটির ব্র্যান্ড ওয়ারেন্টি উপলব্ধ
                  আছে কিনা। বিভিন্ন ওয়ারেন্টির ধরণ এবং ম্যানুফ্যাকচারারের সাথে
                  যোগাযোগ করার উপায় সম্পর্কিত তথ্যের জন্য আমাদের ওয়ারেন্টি
                  পলিসি পড়ুন।
                </p>
                <p class="fw-bold mt-3">বেবি, টয় এবং কিডস</p>
                <p>
                  <b>কাপড়, পোশাক, সানগ্লাস, জুতা এবং আনুষাঙ্গিক</b>
                  (দেশের বাইরে থেকে যে সকল নন- Accessories-bazar পণ্য শিপ হয়ে থাকে)
                  যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ,ত্রূটিপূর্ণ, ভুল, অথবা
                  অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                  ইস্যু করা হবে।
                  <b>
                    টয় এবং গেমস, বেবি কেয়ার এবং হাইজিন, বেবি গিয়ার, ডায়াপার এবং
                    পটি, ফিডিং এবং নার্সিং
                  </b>
                  যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রূটিপূর্ণ, ভুল, অথবা
                  অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                  ইস্যু করা হবে। নোট: রিটার্ন পলিসির সময়কাল শেষ হবার পর যে কোনো
                  ডিভাইস সম্পর্কিত ইস্যুর জন্য, অনুগ্রহ করে চেক করুন পণ্যটির
                  ব্র্যান্ড ওয়ারেন্টি উপলব্ধ আছে কিনা। বিভিন্ন ওয়ারেন্টির ধরণ
                  এবং ম্যানুফ্যাকচারারের সাথে যোগাযোগ করার উপায় সম্পর্কিত
                  তথ্যের জন্য আমাদের ওয়ারেন্টি পলিসি পড়ুন।
                </p>
                <p class="fw-bold mt-3">গ্রোসারি শপ</p>
                <p>
                  <b>
                    বেকারি, বেভারেজ, বেকিং এবং কুকিং, সিগার এবং সিগারেট, ডেইরি,
                    প্যাকেজড ফুড, পার্টি অ্যাক্সেসোরিজ
                  </b>
                  যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রূটিপূর্ণ, ভুল, অথবা
                  অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                  ইস্যু করা হবে।
                </p>

                <p class="fw-bold mt-3">অন্যান্য ক্যাটেগরি</p>
                <p>
                  <b>বুকস এন্ড স্টেশনারি</b>
                  <p>
                    যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রূটিপূর্ণ, ভুল, অথবা
                    অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                    ইস্যু করা হবে।
                  </p>

                  <b>পেট সাপ্লাইস</b>
                  <p>
                    যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রূটিপূর্ণ, ভুল, অথবা
                    অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                    ইস্যু করা হবে।
                  </p>

                  <b>অটোমোটিভ এবং মোটরসাইকেল অ্যাক্সেসোরিজ এবং অন্যান্য</b>
                  <p>
                    যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রূটিপূর্ণ, ভুল, অথবা
                    অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                    ইস্যু করা হবে। যেসকল পণ্য নন-রিটার্নেবল: যে কোনো বাহন (গাড়ি,
                    বাইক ইত্যাদি), ইনস্টলেশন সার্ভিস
                  </p>

                  <b>মেডিকেল এবং ইন্ডাস্ট্রিয়াল জিনিসপত্র</b>
                  <p>
                    যদি ডেলিভারিকৃত পণ্যটি ক্ষতিগ্রস্থ, ত্রূটিপূর্ণ, ভুল, অথবা
                    অসম্পূর্ণ হয়, তাহলে Accessories-bazar এর নিয়মানুযায়ী রিফান্ডটি
                    ইস্যু করা হবে।
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default withRouter(Return);
